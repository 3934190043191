.validation-advice {
  position: relative;
  background: #d50000;
  width: 150px;
  text-align: center;
  border-radius: 3px;
  padding: 3px 0;
  color: white;
  margin-top: 10px;

  &:after {
    bottom: 100%;
    left: 10%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-color: rgba(213, 0, 0, 0);
    border-bottom-color: #d50000;
    border-width: 5px;
    margin-left: -5px;
  }
}
